<template>
  <div class="testimonials_parent">
    <h2 class="header">An idea is mightier than a sword</h2>
    <div v-if="$bus.siteData.testimonials" :id="ID">
      <div class="testimonial" v-for="(t, ix) in [...$bus.siteData.testimonials, ...$bus.siteData.testimonials]" 
        :key="ix">
        <div class="photo" >
          <div class="inner" :style="{
            background: 'url(' + t.photo.url + ') center / cover no-repeat'
          }"></div>
        </div>
        <h3 class="mt20 center fs20">{{t.name}}</h3>
        <h6 class="fs14 center" style="color: #777; font-weight: normal">{{t.designation}}</h6>
        <h1 class="mxauto left pl20" style="max-width: 600px" >"</h1>
        <p  style="max-width: 600px; font-weight: 600" class="mxauto fs16 center px32">
          {{t.testimonial}}
        </p>
        <h1 style="max-width: 600px"  class="pr20 mxauto right">"</h1>
      </div>
    </div>
  </div>
    
</template>

<script>
export default {
  name: 'testimonials',
  data () {
    return {
      ID: this.random()
    }
  },
  watch: {
    '$bus.siteData': {
      handler: function () {
         /* eslint-disable no-undef */
         this.$nextTick(() => {
            let slidesToShow = this.$mq == 'mobile' ? 1 : 3
            $(`#${this.ID}`).slick({
              slidesToShow,
              arrows: true
            })
        })
      },
      deep: true
    }
  },
  mounted () {
    /* eslint-disable no-undef */
    let slidesToShow = this.$mq == 'mobile' ? 1 : 3
    $(`#${this.ID}`).slick({
      slidesToShow,
      arrows: true
    })
  },
  methods: {
    random: function () {
      return Math.random().toString(36).replace('0.', '')
    }
  }
}
</script>

<style lang="css">
  .header {
    text-align: center;
    padding-bottom: 40px;
    font-size: 28px;
  }
  .testimonial {
    padding: 12px;
    padding-top: 40px;
    border: 1px solid #ddd;
  }
  .testimonials_parent {
    background: #f5f5f5;
    padding: 40px 100px;
  }
  @media (max-width: 800px) {
    .testimonials_parent {
      padding: 40px;
    }
  }

  .testimonials_parent h1{
    font-style: italic;
    color: #D8D8D8;
    font-size: 72px;
  }
  .slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 32px;
    line-height: 1;
    opacity: .75;
    color: #252525 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-slide:focus {
    outline: none;
  }
  .photo {
    width: 150px;
    height: 150px;
    display: flex;
    margin-top: 40px;
    margin: auto;
    /*background: url('../assets/testimonial_bg.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; */
  }
  .photo .inner {
    width: 150px;
    height: 150px;
    margin: auto;
    border-radius: 100px;
    position: relative;
    left: 4px;
    top: 4px;

  }
  .slick-slide {
    opacity: 0.6;
    background: white;
    transform: scale(0.85);
    transition: .4s;
  }
  .slick-current + .slick-slide {
    /* padding: 12px; */
    opacity: 1;
    transform: scale(0.95)
  }
</style>