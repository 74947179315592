<template>
  <div class="social-drawer">
    <div v-for="i in icons" :key="i.icon" class="iconDiv">
      <i  
        @click="redirect(i)"
        @mouseover="active = i.icon" @mouseout="active = ''" 
        :class="[i.icon + ' icon']" :style="{color: active == i.icon ? i.fill : ''}"></i>
    </div>
  </div>
</template>

<style lang="css" scoped>

  .social-drawer {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 12px;
    top: 40vh;
    z-index: 50;
    padding: 8px 4px 20px 8px;
    border: 1px solid #eee;
    border-radius: 36px;
    background: white;
  }
  
  .icon {
    font-size: 24px;
    cursor: pointer;
    transition: .3s;
  }
  .icon:hover {
    font-size: 28px;
  }
  .iconDiv {
    display: flex;
    width: 32px;
    height: 32px;
    flex-direction: column;
    padding: 12px 0px;
  }
</style>

<script>
export default {
  name: 'social-drawer',
  data () {
    return {
      active: '',
      icons: [{
        link: 'linkedIn',
        icon: 'linkedin',
        fill: '#2867B2'
      }, {
        link: 'instagram',
        icon: 'instagram',
        fill: '#C13584'
      }, {
        link: 'facebook',
        icon: 'facebook',
        fill: '#4267B2'
      }, {
        link: 'youtube',
        icon: 'youtube',
        fill: '#ff0000'
      }]
    }
  },
  methods: {
    redirect: function (i) {
      window.location.href = this.$bus.siteData[i.link + 'Url']
    }
  }
}
</script>