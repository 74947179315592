<template>
  <div>
    <h4 class="mb20 pt40 center">What's new</h4>
    <div v-if="formattedEvents.length > 0" :id="ID" class="flex">
      <div
        v-for="e in formattedEvents"
        :key="e.id"
        class="whatsnew_card"
        @click="$bus.redirect(e.link, false, true)"
        :style="{ borderBottom: `6px solid ${e.color}` }"
      >
        <div
          class="img"
          :style="{
            background: `url(${(e.Poster || {}).url}) center / cover no-repeat`
          }"
        ></div>
        <div class="px24 bottom relative">
          <div
            class="w48 h48 br40 absolute flex items-center centered"
            :style="{
              background: e.color,
              left: 'calc(50% - 24px)',
              top: '-24px'
            }"
          >
            <div
              class="w20 h20"
              :style="{
                background: 'url(' + e.icon + ') center / contain no-repeat'
              }"
            ></div>
          </div>
          <h4 class="nowrap mt12" style="font-size: 16px; margin-bottom: 12px;">
            {{ e.Title }}
          </h4>
          <div
            style="height: 60px; overflow: hidden;"
            class="desc pb40"
            v-html="e.Information"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import moment from "moment";
export default {
  name: "whatsnew",
  data() {
    return {
      events: [],
      courses: [],
      eventsLoaded: true,
      coursesLoaded: true,
      ID: this.random()
    };
  },
  watch: {
    formattedEvents: {
      handler: function (v) {

        setTimeout(() => {
          // Waiting for a second since we want all the 
          // cards to render
          /* eslint-disable no-undef */
          this.$nextTick(() => {
            if (v?.length > 0 && this.loaded) {
              let slidesToShow = this.$mq == "tablet" ? 3 : (this.$mq == 'mobile' ? 1 : 5);
              $(`#${this.ID}`).slick({
                slidesToShow,
                arrows: true
              });
            }
          });
          if (v?.length > 0 && this.loaded) {
            let slidesToShow = this.$mq == "mobile" ? 1 : (this.$mq == 'mobile' ? 1 : 5);
            $(`#${this.ID}`).slick({
              slidesToShow,
              arrows: true
            });
          }
        }, 1000)
         
      },
      deep: true
    }
  },
  mounted() {
    this.getEvents();
    this.getCourses();
  },
  computed: {
    loaded: function () {
      return this.coursesLoaded && this.eventsLoaded
    },
    formattedEvents: function() {
      let events = [...(this.events || []), ...(this.courses || [])];
      let verticals = this?.$bus?.siteData?.verticals;
      if (verticals) {
        let arcause = verticals.find(v => v.name == "Arcause");
        let ideace = verticals.find(v => v.name == "Ideace");
        let acedge = verticals.find(v => v.name == 'Acedge');
        events
          .filter(ev => !ev.hide)
          .map(ev => {
            // if (ev) {
            //   ev.Title = ev.title
            //   ev.Poster = ev.image
            //   ev.Information = ev.description
            //   ev.icon = acedge?.icon?.url;
            //   ev.color = acedge?.color;
            // } else 
            if (ev.type == "campaign") {
              ev.icon = arcause?.icon?.url;
              ev.color = arcause?.color;
              ev.link = `https://ethosempowers.com/ideace/${ev.Title}`
            } else if (ev.image) {
              ev.Title = ev.title
              ev.Poster = ev.image
              ev.Information = ev.description
              ev.icon = acedge?.icon?.url;
              ev.color = acedge?.color;
            } else if (ev) {
              ev.icon = ideace?.icon?.url;
              ev.color = ideace?.color;
              ev.link = `https://ethosempowers.com/ideace/${ev.Title}`
            }
            return ev;
          });
      }
      return events;
    }
  },
  methods: {
    getEvents: function() {
      let today = moment()._d;
      let filter = qs.stringify({
        _where: {
          Start_lte: today,
          End_gte: today,
          hide: false
        },
        _limit: -1
      });
      this.$req
        .get(`events?${filter}`)
        .then(r => {
          this.events = r.data;
          this.eventsLoaded = true
        })
        .catch(e => console.log(e));
    },
    getCourses: function () {
      this.$req
        .get(`acedge`)
        .then(r => {
          this.courses = r?.data?.courses.filter(c => c.showOnWhatsNew) || []
          this.coursesLoaded = true
        })
        .catch(e => console.log(e));
    },
    random: function() {
      return Math.random()
        .toString(36)
        .replace("0.", "");
    }
  }
};
</script>

<style>
.whatsnew_card {
  border: 1px solid #eee;
  width: calc(220px - 20px);
  margin: 12px 20px 12px 0px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: fit-content;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  height: 340px;
}
.whatsnew_card .label {
  color: white;
  right: -8px;
  top: 20px;
  position: absolute;
  padding: 4px 12px;
  border-radius: 2px;
  font-size: 11px;
}
.whatsnew_card .desc * {
  font-size: 12px;
}
@media (max-width: 800px) {
  .whatsnew_card {
    width: calc(232px - 32px);
  }
}
.whatsnew_card .img {
  width: 100%;
  height: 200px;
}
.whatsnew_card .bottom {
  width: 100%;
  padding: 12px 24px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}
</style>
