var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"mb20 pt40 center"},[_vm._v("What's new")]),(_vm.formattedEvents.length > 0)?_c('div',{staticClass:"flex",attrs:{"id":_vm.ID}},_vm._l((_vm.formattedEvents),function(e){return _c('div',{key:e.id,staticClass:"whatsnew_card",style:({ borderBottom: `6px solid ${e.color}` }),on:{"click":function($event){return _vm.$bus.redirect(e.link, false, true)}}},[_c('div',{staticClass:"img",style:({
          background: `url(${(e.Poster || {}).url}) center / cover no-repeat`
        })}),_c('div',{staticClass:"px24 bottom relative"},[_c('div',{staticClass:"w48 h48 br40 absolute flex items-center centered",style:({
            background: e.color,
            left: 'calc(50% - 24px)',
            top: '-24px'
          })},[_c('div',{staticClass:"w20 h20",style:({
              background: 'url(' + e.icon + ') center / contain no-repeat'
            })})]),_c('h4',{staticClass:"nowrap mt12",staticStyle:{"font-size":"16px","margin-bottom":"12px"}},[_vm._v(" "+_vm._s(e.Title)+" ")]),_c('div',{staticClass:"desc pb40",staticStyle:{"height":"60px","overflow":"hidden"},domProps:{"innerHTML":_vm._s(e.Information)}})])])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }